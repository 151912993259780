<template>
  <b-modal
    visible
    scrollable
    centered
    size="s"
    :title="'Создание новой категории'"
    @hidden="onClose"
    @ok="onClose"
  >
    <loading v-if="isLoading">
      Загрузка данных
    </loading>
    <div>
      <b-row>
        <b-col>
          <b-form-group
            label="Название категории"
          >
            <b-form-input
              v-model="name"
              type="text"
              :placeholder="'Введите название'"
              trim
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Тип категории">
            <b-form-select
              v-model="TagType"
              :options="optionsTag"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <template #modal-footer>
      <div>
        <b-button
          variant="primary"
          :type="$const.PRIMARY_BUTTON"
          @click="onClickCreateTag"
        >
          Создать
        </b-button>
        <b-button
          variant="danger"
          :type="$const.PRIMARY_BUTTON"
          class="ml-2"
          @click="onClose"
        >
          Отмена
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import Loading from '@/components/Loading';

export default {
  name: 'AddTagModal',
  components: {
    Loading,
  },
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    params: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      name: '',
      TagType: null,
      optionsTag: [
        { value: null, text: 'Выберите тип' },
        { value: 1, text: 'Категория' },
        { value: 2, text: 'Популярное' },
      ],
    };
  },
  methods: {
    async onClose() {
      this.$emit('input', false);
    },
    async onClickCreateTag() {
      const params = {
        name: this.name,
        isActive: true,
        TagType: this.TagType,
      };
      await this.$store.dispatch(this.$types.LABORATORY_TAG_CREATE, params);
      await this.$store.dispatch(this.$types.GET_LABORATORY_TAGS, this.params);
      this.onClose();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
